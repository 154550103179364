import React, { useState } from 'react';
import {
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle
} from 'reactstrap';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';

const i18nOpts = { scope: 'components.admin.units.list.buildingModelFilter.index' };

// Add filter when needed
const BuildingModelFilter = ({ buildingModels, onChange }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedBuildingModel, setSelectedBuildingModel] = useState(null);

  const onToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const onToggleBuildingModel = (buildingModel) => {
    setSelectedBuildingModel(buildingModel);
    onChange(buildingModel);
  };

  return (
    <div>
      <Dropdown isOpen={isDropdownOpen} toggle={onToggleDropdown}>
        <DropdownToggle color="outline-secondary" size="sm" caret disabled={!buildingModels.length}>
          <span className="mr-2 text-muted d-none d-sm-inline-block">{i18n.t('buildingModel', i18nOpts)}</span>
          {selectedBuildingModel?.name ?? i18n.t('all', i18nOpts)}
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={() => onToggleBuildingModel(null)}>
            {i18n.t('all', i18nOpts)}
          </DropdownItem>
          {
            buildingModels.map((c) => (
              <DropdownItem
                key={`building-model-${c.id}`}
                onClick={() => onToggleBuildingModel(c)}
              >
                {c.name}
              </DropdownItem>
            ))
          }
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

BuildingModelFilter.propTypes = {
  onChange: PropTypes.func,
  buildingModels: PropTypes.arrayOf(PropTypes.object)
};

BuildingModelFilter.defaultProps = {
  onChange: () => {},
  buildingModels: []
};

export default BuildingModelFilter;

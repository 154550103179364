import React from 'react';
import i18n from 'i18n-js';
import { Link } from 'gatsby';
import { Table } from 'reactstrap';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import v from 'voca';

import './styles.scss';
import { formatNumber } from '../../../../../utils/currencyUtils';
import ActionsMenu from '../../../../global/ActionsMenu';
import { deleteUnit } from '../../../../../store/actions/unitActions';
import { isAdmin } from '../../../../../utils/authUtils';
import { getUnitAvailabilityName } from '../../../../../utils/unitUtils';

const i18nOpts = { scope: 'components.admin.units.list.listView.index' };

const ListView = ({ units, ...props }) => {
  const onEdit = (unit) => {
    navigate(`/admin/units/form?id=${unit.id}`);
  };

  const onDelete = (unitId) => {
    props.deleteUnit(unitId);
  };

  return (
    <Table responsive bordered id="units-table">
      <thead>
        <tr>
          <th>{i18n.t('suiteNumber', i18nOpts)}</th>
          <th>{i18n.t('floor', i18nOpts)}</th>
          <th>{i18n.t('bedrooms', i18nOpts)}</th>
          <th>{i18n.t('bathrooms', i18nOpts)}</th>
          <th>{i18n.t('buildingModel', i18nOpts)}</th>
          <th>{i18n.t('sqFt', i18nOpts)}</th>
          <th>{i18n.t('availability', i18nOpts)}</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {
        units.map((unit) => (
          <tr key={`unit-${unit.id}`} className={`row-${v.slugify(unit.availability)}`}>
            <td>
              <Link to={`/admin/units/${unit.id}`}>
                {unit.name}
              </Link>
            </td>
            <td>{unit.floor}</td>
            <td>{unit.buildingModel.bedrooms}</td>
            <td>{unit.buildingModel.bathrooms}</td>
            <td>{unit.buildingModel.name}</td>
            <td>{formatNumber(unit.buildingModel.size)}</td>
            <td>
              <span className={`badge text-white badge-${v.slugify(unit.availability)}`}>
                {getUnitAvailabilityName(unit.availability)}
              </span>
            </td>
            <td>
              {isAdmin() && (
                <ActionsMenu
                  item={unit}
                  onEdit={onEdit}
                  onDelete={onDelete}
                />
              )}
            </td>
          </tr>
        ))
      }
      </tbody>
    </Table>
  );
};

export default connect((store) => ({
  units: store.units.units
}), {
  deleteUnit
})(ListView);

import React, { Component } from 'react';
import { Link, navigate } from 'gatsby';
import {
  Button, Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import { HiShare } from 'react-icons/hi';

import { isAdmin } from '../../../../utils/authUtils';
import Details from './Details';
import Options from './Options';
import Images from './Images';
import { getUnit } from '../../../../store/actions/unitActions';
import { ModalNames } from '../../../../constants';
import { hasCompanyDomain } from '../../../../utils/companyUtils';
import ModalService from '../../../../services/ModalService';
import DirectLinkModal from '../../../global/DirectLinkModal';
import QRCodeModal from '../../../global/QRCodeModal';
import Modifications from '../../../global/Modifications';
import Loading from '../../../ui/Loading';

const i18nOpts = { scope: 'components.admin.units.show.index' };

const Tabs = Object.freeze({
  DETAILS: 'details',
  OPTIONS: 'options',
  IMAGES: 'images',
  MODIFICATIONS: 'modifications'
});

class Show extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      activeTab: Tabs.DETAILS
    };

    this.onChangeTab = this.onChangeTab.bind(this);
    this.onOpenDirectLinkModal = this.onOpenDirectLinkModal.bind(this);
  }

  componentDidMount() {
    const { id } = this.props;
    const parsedId = parseInt(id, 10);
    this.setState({ id: parsedId }, this.loadUnit);
  }

  onChangeTab(tab) {
    this.setState({ activeTab: tab });
  }

  onOpenDirectLinkModal() {
    const { unit } = this.props;
    const { hashId } = unit;

    const params = {
      path: `/unit/${hashId}`,
      label: unit.name
    };
    ModalService.open(ModalNames.DIRECT_LINK_MODAL, params);
  }

  loadUnit() {
    const { props } = this;
    const { id } = this.state;

    if (!id) return;

    props.getUnit(id)
      .then()
      .catch(() => navigate('/admin/units'));
  }

  render() {
    const { activeTab } = this.state;
    const { unit, loading, currentCompany } = this.props;

    if (!unit || loading) return <Loading loading fullScreen />;

    return (
      <div>
        <Helmet title={unit.name} />

        <div className="d-flex align-items-center mb-4">
          <div className="flex-grow-1">
            <h2>{unit.name}</h2>
          </div>
          <div>
            {isAdmin() && (
              <>
                {hasCompanyDomain(currentCompany) && (
                  <Button color="secondary" onClick={this.onOpenDirectLinkModal}>
                    <HiShare size="1rem" className="col-light mr-2" />
                    <span>{i18n.t('buttons.shareLink')}</span>
                  </Button>
                )}

                <Link to={`/admin/units/form?id=${unit.id}`} className="btn btn-primary ml-3">
                  <i className="far fa-edit mr-2" />
                  {i18n.t('buttons.edit')}
                </Link>
              </>
            )}
          </div>
        </div>

        <Nav tabs>
          <NavItem>
            <NavLink
              className={`${activeTab === Tabs.DETAILS ? 'active' : ''}`}
              onClick={() => this.onChangeTab(Tabs.DETAILS)}
            >
              {i18n.t('tabs.details', i18nOpts)}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`${activeTab === Tabs.OPTIONS ? 'active' : ''}`}
              onClick={() => this.onChangeTab(Tabs.OPTIONS)}
            >
              {i18n.t('tabs.options', i18nOpts)}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`${activeTab === Tabs.IMAGES ? 'active' : ''}`}
              onClick={() => this.onChangeTab(Tabs.IMAGES)}
            >
              {i18n.t('tabs.images', i18nOpts)}
            </NavLink>
          </NavItem>
          {isAdmin && (
            <NavItem>
              <NavLink
                className={`${activeTab === Tabs.MODIFICATIONS ? 'active' : ''}`}
                onClick={() => this.onChangeTab(Tabs.MODIFICATIONS)}
              >
                {i18n.t('tabs.modifications', i18nOpts)}
              </NavLink>
            </NavItem>
          )}
        </Nav>
        <TabContent activeTab={activeTab} className="py-4">
          <TabPane tabId={Tabs.DETAILS}>
            <Details />
          </TabPane>
          <TabPane tabId={Tabs.OPTIONS}>
            <Options />
          </TabPane>
          <TabPane tabId={Tabs.IMAGES}>
            <Images />
          </TabPane>
          {isAdmin() && (
            <TabPane tabId={Tabs.MODIFICATIONS}>
              <Modifications instance={unit} />
            </TabPane>
          )}
        </TabContent>

        <DirectLinkModal />
        <QRCodeModal />
      </div>
    );
  }
}

export default connect((store) => ({
  unit: store.units.unit,
  loading: store.units.getUnit.loading,
  currentCompany: store.companies.currentCompany
}), {
  getUnit
})(Show);

import React, { useState } from 'react';
import {
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle
} from 'reactstrap';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';

const i18nOpts = { scope: 'components.admin.units.list.bathroomFilter.index' };

const BathroomFilter = ({ bathrooms, onChange }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedBathroom, setSelectedBathroom] = useState(null);

  const onToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const onToggleBathroom = (bathroom) => {
    setSelectedBathroom(bathroom);
    onChange(bathroom);
  };

  const getDescription = (quantity) => {
    if (quantity > 1) return i18n.t('bathroomsQuantity', { ...i18nOpts, quantity });
    return i18n.t('bathroom', i18nOpts);
  };

  return (
    <div>
      <Dropdown isOpen={isDropdownOpen} toggle={onToggleDropdown}>
        <DropdownToggle color="outline-secondary" size="sm" caret disabled={!bathrooms.length}>
          <span className="mr-2 text-muted d-none d-sm-inline-block">{i18n.t('bathrooms', i18nOpts)}</span>
          {selectedBathroom ?? i18n.t('all', i18nOpts)}
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={() => onToggleBathroom(null)}>
            {i18n.t('all', i18nOpts)}
          </DropdownItem>
          {
            bathrooms.map((b) => (
              <DropdownItem
                key={`bathroom-${b}`}
                onClick={() => onToggleBathroom(b)}
              >
                {getDescription(b)}
              </DropdownItem>
            ))
          }
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

BathroomFilter.propTypes = {
  onChange: PropTypes.func,
  bathrooms: PropTypes.arrayOf(PropTypes.number).isRequired
};

BathroomFilter.defaultProps = {
  onChange: () => {},
};

export default BathroomFilter;

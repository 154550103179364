import { navigate } from 'gatsby';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import {
  Button, Col, Form, FormGroup, Input, Label, Row, Spinner
} from 'reactstrap';
import { connect } from 'react-redux';
import v from 'voca';
import { BiTrash } from 'react-icons/bi';

import api from '../../../../api';
import { listBuildingModelsQuery } from '../../../../graphql';
import { getParam } from '../../../../utils/paramsUtils';
import { hasFormValues } from '../../../../utils/formUtils';
import { getUnit, saveUnit, deleteUnit } from '../../../../store/actions/unitActions';
import { parseBoolean, parseToFloat, parseToInt } from '../../../../utils/parserUtils';
import DeleteButton from '../../../global/DeleteButton';
import { getError } from '../../../../utils/requestUtils';
import InputError, { isInputInvalid } from '../../../ui/InputError';
import { stripToEmpty, stripToNull } from '../../../../utils/stringUtils';
import { getLocalImageUrl } from '../../../../utils/imageUtils';
import { UnitAvailabilities } from '../../../../constants';
import { getUnitAvailabilityName } from '../../../../utils/unitUtils';

const i18nOpts = { scope: 'components.admin.units.form.index' };

function onCancel() {
  navigate('/admin/units');
}

function fetchBuildingModelsAsync(companyId) {
  const variables = { filter: { companyId } };

  return api.graphql(listBuildingModelsQuery, variables)
    .then(({ data: { items } }) => Promise.resolve(items));
}

class UnitForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      form: {},
      saving: false,
      image: undefined,
      buildingModels: [],
      error: null
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onDeleteImage = this.onDeleteImage.bind(this);
  }

  componentDidMount() {
    this.loadBuildingModels();

    const id = parseInt(getParam('id'), 10);
    this.setState({ id }, this.loadUnit);
  }

  onTextChange(event) {
    const { form } = this.state;
    const { name, value } = event.target;

    form[name] = value;
    this.setState({ form });
  }

  onSave() {
    const { props } = this;
    const { form, image } = this.state;

    delete form.imageUrl;

    const input = {
      ...form,
      name: stripToEmpty(form.name),
      buildingModelId: parseInt(form.buildingModelId, 10) || 0,
      tourUrl: stripToNull(form.tourUrl),
      openTourUrlInOverlayWindow: parseBoolean(form.openTourUrlInOverlayWindow),
    };

    const price = parseToFloat(form.price);
    if (!Number.isNaN(price) || price === null) input.price = price;

    const floor = parseToInt(form.floor, 10);
    if (!Number.isNaN(floor) || floor === null) input.floor = floor;

    if (!v.isBlank(form.availability)) input.availability = form.availability;

    this.setState({ saving: true });

    const variables = { input, image };
    props.saveUnit(variables)
      .then(() => {
        navigate('/admin/units');
      })
      .catch((e) => {
        const error = getError(e);
        this.setState({ error });
        if (v.isString(error)) toast.error(error);
      })
      .finally(() => {
        this.setState({ saving: false });
      });
  }

  onDelete() {
    const { props } = this;
    const { unit } = this.props;

    props.deleteUnit(unit.id)
      .then(() => {
        navigate('/admin/units');
      });
  }

  onImageChange(event) {
    const { name } = event.target;
    const image = event.target.files[0];
    this.setState({ [name]: image });
  }

  onDeleteImage() {
    this.setState({ image: null });
  }

  getPreviewImageUrl() {
    const { image } = this.state;
    if (image === null) return null;
    return getLocalImageUrl(image) || this.getImageUrl();
  }

  getImageUrl() {
    const { unit } = this.props;
    const { id } = this.state;
    if (!unit) return null;

    return unit.id === id ? unit.imageUrl : null;
  }

  loadUnit() {
    const { props } = this;
    const { id } = this.state;

    if (this.isNew()) return;

    props.getUnit(id)
      .then(() => {
        const { unit } = this.props;

        if (unit) {
          const {
            name, buildingModel, price, floor, availability, floorLocation, view, tourUrl,
            openTourUrlInOverlayWindow
          } = unit;
          const form = {
            id,
            name,
            buildingModelId: buildingModel.id,
            price,
            floor,
            availability,
            floorLocation,
            tourUrl,
            openTourUrlInOverlayWindow,
            view
          };
          this.setState({
            form, saving: false, error: null
          });
        } else navigate('/admin/units');
      })
      .catch(() => {
        navigate('/admin/units');
      });
  }

  isNew() {
    const { id } = this.state;
    return !id;
  }

  loadBuildingModels() {
    const { currentCompany } = this.props;
    fetchBuildingModelsAsync(currentCompany.id)
      .then((buildingModels) => {
        this.setState({ buildingModels });
      })
      .catch(() => {});
  }

  render() {
    const {
      form, saving, buildingModels, error
    } = this.state;
    const isNew = this.isNew();
    const previewImageUrl = this.getPreviewImageUrl();
    const hasValues = hasFormValues(form);
    const title = this.isNew() ? i18n.t('newTitle', i18nOpts) : i18n.t('editTitle', i18nOpts);

    return (
      <div>
        <Helmet title={title} />

        <h2 className="mb-4">{title}</h2>

        <Form onSubmit={this.onSave}>
          <Row form>
            <Col lg="4" md="6" sm="8" xs="12">
              <FormGroup>
                <Label for="buildingModelId">{i18n.t('buildingModelId', i18nOpts)}</Label>
                <Input
                  type="select"
                  name="buildingModelId"
                  id="buildingModelId"
                  value={form.buildingModelId || ''}
                  onChange={this.onTextChange}
                  invalid={isInputInvalid(error, 'buildingModelId')}
                >
                  <option value="">{i18n.t('select.select')}</option>
                  {
                    buildingModels.map((bm) => (
                      <option key={`building-model-option-${bm.id}`} value={bm.id}>
                        {bm.name}
                      </option>
                    ))
                  }
                </Input>
                <InputError name="buildingModelId" error={error} />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col lg="4" md="6" sm="8" xs="12">
              <FormGroup>
                <Label for="buildingModelName">{i18n.t('name', i18nOpts)}</Label>
                <Input
                  type="text"
                  name="name"
                  id="buildingModelName"
                  value={form.name || ''}
                  onChange={this.onTextChange}
                  invalid={isInputInvalid(error, 'name')}
                />
                <InputError name="name" error={error} />
              </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col lg="4" md="6" sm="8" xs="12">
              <FormGroup>
                <Label for="buildingModelPrice">{i18n.t('price', i18nOpts)}</Label>
                <Input
                  type="number"
                  name="price"
                  id="buildingModelPrice"
                  value={form.price || ''}
                  onChange={this.onTextChange}
                  invalid={isInputInvalid(error, 'price')}
                />
                <InputError name="price" error={error} />
              </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col lg="4" md="6" sm="8" xs="12">
              <FormGroup>
                <Label for="buildingModelFloor">{i18n.t('floor', i18nOpts)}</Label>
                <Input
                  type="number"
                  name="floor"
                  id="buildingModelFloor"
                  value={form.floor || ''}
                  onChange={this.onTextChange}
                  invalid={isInputInvalid(error, 'floor')}
                />
                <InputError name="floor" error={error} />
              </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col lg="4" md="6" sm="8" xs="12">
              <FormGroup>
                <Label for="buildingModelFloorLocation">{i18n.t('floorLocation', i18nOpts)}</Label>
                <Input
                  type="text"
                  name="floorLocation"
                  id="buildingModelFloorLocation"
                  value={form.floorLocation || ''}
                  onChange={this.onTextChange}
                  invalid={isInputInvalid(error, 'floorLocation')}
                />
                <InputError name="floorLocation" error={error} />
              </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col lg="4" md="6" sm="8" xs="12">
              <FormGroup>
                <Label for="buildingModelView">{i18n.t('view', i18nOpts)}</Label>
                <Input
                  type="text"
                  name="view"
                  id="buildingModelView"
                  value={form.view || ''}
                  onChange={this.onTextChange}
                  invalid={isInputInvalid(error, 'view')}
                />
                <InputError name="view" error={error} />
              </FormGroup>
            </Col>
          </Row>

          <Row form className="mb-4">
            <Col md="6" sm="8" xs="12" lg="4">
              <FormGroup>
                <Label for="image">{i18n.t('image', i18nOpts)}</Label>
                {!v.isBlank(previewImageUrl) && (
                  <div className="mb-2 position-relative">
                    <img src={previewImageUrl} alt="" className="w-100" />
                    <button
                      type="button"
                      className="text-danger rounded-circle bg-dark delete-icon"
                      onClick={this.onDeleteImage}
                      title={i18n.t('buttons.delete')}
                    >
                      <BiTrash size="1.1rem" />
                    </button>
                  </div>
                )}
                <Input
                  type="file"
                  name="image"
                  id="image"
                  accept="image/*"
                  onChange={this.onImageChange}
                />
              </FormGroup>

              <FormGroup className="mb-1">
                <Label for="tourUrl">{i18n.t('tourUrl', i18nOpts)}</Label>
                <Input
                  type="text"
                  name="tourUrl"
                  id="tourUrl"
                  value={form.tourUrl || ''}
                  onChange={this.onTextChange}
                  invalid={isInputInvalid(error, 'tourUrl')}
                />
                <InputError error={error} name="tourUrl" />
              </FormGroup>

              <FormGroup check className="mb-3">
                <Label check className="text-muted">
                  <Input
                    type="checkbox"
                    name="openTourUrlInOverlayWindow"
                    id="openTourUrlInOverlayWindow"
                    value={!parseBoolean(form.openTourUrlInOverlayWindow)}
                    checked={parseBoolean(form.openTourUrlInOverlayWindow)}
                    onChange={this.onTextChange}
                  />
                  <small>{i18n.t('openTourUrlInOverlayWindow', i18nOpts)}</small>
                </Label>
              </FormGroup>
              <FormGroup>
                <Label for="availability">{i18n.t('availability', i18nOpts)}</Label>
                <Input
                  type="select"
                  name="availability"
                  id="availability"
                  value={form.availability || ''}
                  onChange={this.onTextChange}
                  invalid={isInputInvalid(error, 'availability')}
                >
                  {
                    Object.values(UnitAvailabilities).map((availability) => (
                      <option key={`availability-option-${availability}`} value={availability}>
                        {getUnitAvailabilityName(availability)}
                      </option>
                    ))
                  }
                </Input>
                <InputError name="availability" error={error} />
              </FormGroup>
            </Col>
          </Row>

        </Form>

        <Button color="primary" className="mr-3" onClick={this.onSave} disabled={saving || !hasValues}>
          {saving && (<Spinner size="sm" className="mr-2" />)}
          {i18n.t('buttons.save')}
        </Button>
        <Button outline color="secondary" onClick={onCancel} disabled={saving} className="mr-3">
          {i18n.t('buttons.cancel')}
        </Button>
        {!isNew && (
          <DeleteButton onDelete={this.onDelete} />
        )}
      </div>
    );
  }
}

export default connect((store) => ({
  unit: store.units.unit,
  currentCompany: store.companies.currentCompany
}), {
  saveUnit,
  getUnit,
  deleteUnit
})(UnitForm);

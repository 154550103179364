import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import ModalService from '../../../../../../../services/ModalService';
import { ModalNames, PaymentVendors } from '../../../../../../../constants';

const Refund = ({ deposit }) => {
  const { confirmed, creditAllowed, paymentVendor } = deposit;

  const onClick = () => {
    ModalService.open(ModalNames.DEPOSIT_REFUND_FORM, { depositId: deposit.id, from: 'depositList' });
  };

  const isStripePayment = paymentVendor === PaymentVendors.STRIPE;

  return (
    <>
      {(isStripePayment && confirmed && creditAllowed) ? (
        <div
          role="button"
          aria-hidden
          onClick={onClick}
          className="col-gray-900 font-weight-500 text-decoration-underline"
        >
          {i18n.t('buttons.refund')}
        </div>
      ) : (
        'N/A'
      )}
    </>
  );
};

Refund.propTypes = {
  deposit: PropTypes.objectOf(PropTypes.any).isRequired
};

Refund.defaultProps = {};

export default Refund;

import {
  GET_UNIT, GET_UNITS, CLEAN_UNITS, SAVE_UNIT, DELETE_UNIT, SET_UNITS_VIEW_MODE
} from './types';
import api from '../../api';
import {
  getUnitQuery, listUnitsQuery, createUnitQuery, updateUnitQuery, deleteUnitQuery
} from '../../graphql';

export function getUnits(variables = {}) {
  return {
    type: GET_UNITS,
    payload: api.graphql(listUnitsQuery, variables)
  };
}

export function getUnit(id) {
  return {
    type: GET_UNIT,
    payload: api.graphql(getUnitQuery, { id })
  };
}

export function cleanUnits() {
  return {
    type: CLEAN_UNITS,
    payload: Promise.resolve()
  };
}

export function saveUnit(variables = {}) {
  const query = variables.input.id ? updateUnitQuery : createUnitQuery;
  return {
    type: SAVE_UNIT,
    payload: api.graphql(query, variables)
  };
}

export function deleteUnit(id) {
  return {
    type: DELETE_UNIT,
    payload: api.graphql(deleteUnitQuery, { id })
  };
}

export function setUnitsViewMode(viewMode) {
  return {
    type: SET_UNITS_VIEW_MODE,
    payload: { viewMode }
  };
}

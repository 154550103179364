import React from 'react';
import i18n from 'i18n-js';

import { isAdmin } from '../../../../utils/authUtils';
import BuildingsIcon from '../../../../assets/icons/sidebar-nav/BuildingsIcon';
import BuildingModelsIcon from '../../../../assets/icons/sidebar-nav/BuildingModelsIcon';
import UnitsIcon from '../../../../assets/icons/sidebar-nav/UnitsIcon';
import OptionTypesIcon from '../../../../assets/icons/sidebar-nav/OptionTypesIcon';
import PalettesIcon from '../../../../assets/icons/sidebar-nav/PalettesIcon';
import PackagesIcon from '../../../../assets/icons/sidebar-nav/PackagesIcon';
import CommunitiesIcon from '../../../../assets/icons/sidebar-nav/CommunitiesIcon';
import SidebarMenuItem from '../components/SidebarMenuItem';

const i18nOpts = { scope: 'layout.admin.sidebar.multiFamilySideBar' };

const MultiFamilySideBar = () => (
  <>
    <SidebarMenuItem to="/admin/projects" Icon={<CommunitiesIcon />} title={i18n.t('menus.projects', i18nOpts)} />
    <SidebarMenuItem to="/admin/projectBuildings" Icon={<BuildingsIcon />} title={i18n.t('menus.buildings', i18nOpts)} />
    <SidebarMenuItem to="/admin/buildingModels" Icon={<BuildingModelsIcon />} title={i18n.t('menus.buildingModels', i18nOpts)} />
    <SidebarMenuItem to="/admin/units" Icon={<UnitsIcon />} title={i18n.t('menus.units', i18nOpts)} />

    {isAdmin() && (
      <>
        <SidebarMenuItem to="/admin/optionTypes" Icon={<OptionTypesIcon />} title={i18n.t('menus.optionTypes', i18nOpts)} />
        <SidebarMenuItem to="/admin/multifamily/palettes" Icon={<PalettesIcon />} title={i18n.t('menus.palettes', i18nOpts)} />
        <SidebarMenuItem to="/admin/multifamily/packages" Icon={<PackagesIcon />} title={i18n.t('menus.packages', i18nOpts)} />
      </>
    )}
  </>
);

MultiFamilySideBar.propTypes = {};

MultiFamilySideBar.defaultProps = {};

export default MultiFamilySideBar;

import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import i18n from 'i18n-js';
import { Link } from 'gatsby';
import { connect } from 'react-redux';

import { isAdmin } from '../../../../utils/authUtils';
import ListEmpty from '../../../common/ListEmpty';
import { getUnits, cleanUnits, setUnitsViewMode } from '../../../../store/actions/unitActions';
import { ViewModes } from '../../../../constants';
import GridView from './GridView';
import ListView from './ListView';
import BathroomFilter from './BathroomFilter';
import BedroomFilter from './BedroomFilter';
import AvailabilityFilter from './AvailabilityFilter';
import { valueExistsInArray } from '../../../../utils/arrayUtils';
import api from '../../../../api';
import ViewModesDropdown from '../../../global/ViewModesDropdown';
import BuildingModelFilter from './BuildingModelFilter';
import ListPagination from '../../../common/ListPagination';
import Loading from '../../../ui/Loading';
import SearchBar from '../../../common/SearchBar';

const i18nOpts = { scope: 'components.admin.units.list.index' };

function fetchBuildingModelsAsync(companyId) {
  const variables = { filter: { companyId } };

  return api.graphql(`
    query ListBuildingModels($filter: BuildingModelFilterInput) {
      items: listBuildingModels(filter: $filter) {
        id
        name
        bedrooms
        bathrooms
      }
    }
  `, variables)
    .then(({ data: { items } }) => Promise.resolve(items));
}

class List extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: {},
      buildingModels: [],
      bathroomsFilter: [],
      bedroomsFilter: [],
      loaded: false,
      isEmpty: true,
      mounted: false
    };

    this.onChangePage = this.onChangePage.bind(this);
    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.onViewModeChange = this.onViewModeChange.bind(this);
    this.searchSubmit = this.searchSubmit.bind(this);
  }

  componentDidMount() {
    const { props } = this;
    const { currentCompany } = this.props;

    props.cleanUnits()
      .then(() => {
        this.loadUnits();
        fetchBuildingModelsAsync(currentCompany.id)
          .then((items) => this.setState(
            { buildingModels: items },
            () => this.setValuesForDropdownFilters()
          ))
          .catch(() => {});
      });
  }

  onChangePage(page) {
    this.loadUnits(page);
  }

  onChangeFilter(filter) {
    this.setState((prevState) => ({ filters: { ...prevState.filters, ...filter } }),
      () => this.afterFilterChanged());
  }

  onViewModeChange(viewMode) {
    const { props } = this;
    props.setUnitsViewMode(viewMode);
  }

  setValuesForDropdownFilters() {
    const { buildingModels } = this.state;
    const bathroomsFilter = [];
    const bedroomsFilter = [];
    buildingModels.forEach((buildingModel) => {
      const { bathrooms, bedrooms } = buildingModel;
      if (!valueExistsInArray(bathroomsFilter, bathrooms) && bathrooms) {
        bathroomsFilter.push(bathrooms);
      }
      if (!valueExistsInArray(bedroomsFilter, bedrooms) && bedrooms) bedroomsFilter.push(bedrooms);
    });

    bathroomsFilter.sort();
    bedroomsFilter.sort();

    this.setState({ bathroomsFilter, bedroomsFilter });
  }

  searchSubmit(e, q) {
    e.preventDefault();

    this.setState((prevState) => ({ filters: { ...prevState.filters, query: q } }),
      () => this.afterFilterChanged());
  }

  loadUnits(page = 1) {
    const { props } = this;
    const { filters, loaded } = this.state;
    const { currentCompany } = this.props;

    const filter = { companyId: currentCompany.id };

    if (filters.bathrooms) filter.bathrooms = filters.bathrooms;
    if (filters.bedrooms) filter.bedrooms = filters.bedrooms;
    if (filters.availability) filter.availability = filters.availability;
    if (filters.buildingModel) filter.buildingModelId = filters.buildingModel?.id;
    if (filters.query) filter.query = filters.query;

    const variables = { filter, page };
    props.getUnits(variables)
      .then(() => {
        if (!loaded) {
          const { units } = this.props;
          const isEmpty = units.length === 0;
          this.setState({ isEmpty, loaded: true });
        }
      })
      .finally(() => this.setState({ mounted: true }));
  }

  afterFilterChanged() {
    const { props } = this;
    props.cleanUnits()
      .then(() => this.loadUnits());
  }

  render() {
    const {
      bathroomsFilter, bedroomsFilter, isEmpty, buildingModels, mounted
    } = this.state;

    if (!mounted) return <Loading loading fullScreen />;

    const {
      units, pagination, loading, viewMode
    } = this.props;

    return (
      <div>
        <Helmet title={i18n.t('title', i18nOpts)} />

        <div className="d-flex flex-column flex-md-row align-md-items-center mb-2">
          <div className="flex-grow-1">
            <h2>{i18n.t('title', i18nOpts)}</h2>
          </div>
        </div>

        <div className="d-flex mb-5 align-items-center flex-wrap gap-3">
          <div className="flex-grow-1">
            <SearchBar
              onSubmit={this.searchSubmit}
              inputProps={{ bsSize: 'md' }}
              searchFields="name or ID"
            />
          </div>
          <div className="d-flex justify-content-end align-items-center flex-wrap gap-3">
            <BuildingModelFilter
              buildingModels={buildingModels}
              onChange={(buildingModel) => this.onChangeFilter({ buildingModel })}
            />

            <BedroomFilter
              bedrooms={bedroomsFilter}
              onChange={(bedrooms) => this.onChangeFilter({ bedrooms })}
            />

            <BathroomFilter
              bathrooms={bathroomsFilter}
              onChange={(bathrooms) => this.onChangeFilter({ bathrooms })}
            />

            <AvailabilityFilter
              onChange={(availability) => this.onChangeFilter({ availability })}
              disabled={isEmpty}
            />

            <ViewModesDropdown viewMode={viewMode} onChange={this.onViewModeChange} />

            {isAdmin() && (
              <Link to="/admin/units/form" className="btn btn-primary">
                <i className="fas fa-plus mr-2" />
                {i18n.t('buttons.add', i18nOpts)}
              </Link>
            )}
          </div>
        </div>

        {loading ? (
          <Loading loading />
        ) : (
          <>
            <ListEmpty loading={loading} items={units} />

            {units.length > 0 && (
              <div className="mb-4">
                {viewMode === ViewModes.GRID ? (
                  <GridView />
                ) : (
                  <ListView />
                )}
              </div>
            )}

            <div className="text-right mt-4">
              <ListPagination pagination={pagination} onPress={this.onChangePage} />
            </div>
          </>
        )}
      </div>
    );
  }
}

export default connect((store) => ({
  units: store.units.units,
  loading: store.units.getUnits.loading,
  pagination: store.units.pagination,
  viewMode: store.units.viewMode,
  currentCompany: store.companies.currentCompany
}), {
  getUnits,
  cleanUnits,
  setUnitsViewMode
})(List);

import React, { useState } from 'react';
import {
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle
} from 'reactstrap';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';

import { UnitAvailabilities } from '../../../../../constants';
import { getUnitAvailabilityName } from '../../../../../utils/unitUtils';

const i18nOpts = { scope: 'components.admin.units.list.availabilityFilter.index' };

const AvailabilityFilter = ({ disabled, onChange }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedAvailability, setSelectedAvailability] = useState(null);

  const onToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const onToggleStatus = (availability) => {
    setSelectedAvailability(availability);
    onChange(availability);
  };

  return (
    <div>
      <Dropdown isOpen={isDropdownOpen} toggle={onToggleDropdown}>
        <DropdownToggle color="outline-secondary" size="sm" caret disabled={disabled}>
          <span className="mr-2 text-muted d-none d-sm-inline-block">{i18n.t('availability', i18nOpts)}</span>
          {selectedAvailability ? getUnitAvailabilityName(selectedAvailability) : i18n.t('all', i18nOpts)}
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={() => onToggleStatus(null)}>
            {i18n.t('all', i18nOpts)}
          </DropdownItem>
          {
            Object.values(UnitAvailabilities).map((availability) => (
              <DropdownItem
                key={`unit-availability-${availability}`}
                onClick={() => onToggleStatus(availability)}
              >
                {getUnitAvailabilityName(availability)}
              </DropdownItem>
            ))
          }
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

AvailabilityFilter.propTypes = {
  onChange: PropTypes.func
};

AvailabilityFilter.defaultProps = {
  onChange: () => {}
};

export default AvailabilityFilter;

import React, { useState } from 'react';
import {
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle
} from 'reactstrap';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';

const i18nOpts = { scope: 'components.admin.units.list.bedroomFilter.index' };

const BedroomFilter = ({ bedrooms, onChange }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedBedroom, setSelectedBedroom] = useState(null);

  const onToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const onToggleBedroom = (bedroom) => {
    setSelectedBedroom(bedroom);
    onChange(bedroom);
  };

  const getDescription = (quantity) => {
    if (quantity > 1) return i18n.t('bedroomsQuantity', { ...i18nOpts, quantity });
    return i18n.t('bedroom', i18nOpts);
  };

  return (
    <div>
      <Dropdown isOpen={isDropdownOpen} toggle={onToggleDropdown}>
        <DropdownToggle color="outline-secondary" size="sm" caret disabled={!bedrooms.length}>
          <span className="mr-2 text-muted d-none d-sm-inline-block">{i18n.t('bedrooms', i18nOpts)}</span>
          {selectedBedroom ?? i18n.t('all', i18nOpts)}
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={() => onToggleBedroom(null)}>
            {i18n.t('all', i18nOpts)}
          </DropdownItem>
          {
            bedrooms.map((b) => (
              <DropdownItem
                key={`bedroom-${b}`}
                onClick={() => onToggleBedroom(b)}
              >
                {getDescription(b)}
              </DropdownItem>
            ))
          }
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

BedroomFilter.propTypes = {
  onChange: PropTypes.func,
  bedrooms: PropTypes.arrayOf(PropTypes.number).isRequired
};

BedroomFilter.defaultProps = {
  onChange: () => {},
};

export default BedroomFilter;

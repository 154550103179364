import { navigate } from 'gatsby';

export const Tabs = Object.freeze({
  COMPANY_AND_BRANDING: 'companyAndBranding',
  SITE_AND_UX: 'siteAndUX',
  SALES_CONFIG: 'salesConfig',
  WORKFLOW: 'workflow',
  COMMUNICATIONS: 'communications',
  ADVANCED_SETTINGS: 'advancedSettings'
});

export function goToCompanies() {
  navigate('/admin/companies');
}

export const DefaultDesign = Object.freeze({
  mainColor: '#515050',
  accentColor: '#FFC44B',
  floorPlanHighlightColor: '#40c3e7',
  fixedDepositAmount: null,
  singleFamilyPriceNote: 'Incl lot, house, GST',
  multiFamilyPriceNote: 'Including GST',
});

export const DefaultMessages = Object.freeze([
  {
    section: 'startPageMessage',
    text: 'Welcome to our build and price tool. Choose everything from your Community down to the finishes of your home.'
  },
  {
    section: 'completePageTitle',
    text: 'Congratulation'
  },
  {
    section: 'completePageMessage',
    text: 'You just designed your dream home! Fill out the form below to receive a link to your custom floorplan in your inbox.'
  },
  {
    section: 'saveButtonText',
    text: 'Save'
  },
  {
    section: 'buyButtonText',
    text: 'Buy Now'
  },
  {
    section: 'quickPossessionText',
    text: 'Quick Possession'
  },
  {
    section: 'modelText',
    text: 'Model'
  },
  {
    section: 'saveQuickPossessionButtonText',
    text: 'Save Quick Possession'
  },
  {
    section: 'verificationText',
    text: '<p>Kick off your home purchasing journey! By clicking the link below you can verify your Buying Power & Identity to take one step closer to purchasing your dream home.</p>'
  }
]);

export const DefaultDisclaimers = Object.freeze([
  {
    section: 'city',
    text: 'Listed cities may not be a complete representation of all available locations. Contact your Area Sales Manager for more information on location.'
  },
  {
    section: 'communityProject',
    text: 'Listed communities may not be a complete representation of all available locations. Contact your Area Sales Manager for more information on location.'
  },
  {
    section: 'lotMap',
    text:
      'This screen shows lot availability. Lots are subject to availability and may change without notice. Online selection does not constitute a formal hold of the chosen lot. Lot location is not confirmed until a lot reservation confirmation from the builder. Please contact sales for more information on how to place down a deposit to hold a lot. The builder reserves the right to make modifications or substitutions should they be necessary. E.&O.E.'
  },
  {
    section: 'productType',
    text:
      'Models, elevations, floorplans, and other renderings shown are artist concepts and only represent one version of the home and measurements are approximate. Interior/exterior renderings as displayed on your device may differ from the product’s true appearance. Measurements and layout may vary based on the elevation, lot, and community. Consult your Area Sales Manager for complete details. E.&O.E.'
  },
  {
    section: 'modelDetails',
    text:
      'Models, elevations, floorplans, and other renderings shown are artist concepts and only represent one version of the home and measurements are approximate. Interior/exterior renderings as displayed on your device may differ from the product’s true appearance. Measurements and layout may vary based on the elevation, lot, and community. Consult your Area Sales Manager for complete details. E.&O.E.'
  },
  {
    section: 'monthlyPaymentDisclaimer',
    text:
      'The monthly payment provided is for illustrative purposes. Values and figures shown are hypothetical and may be subject to change in individual cases. Accuracy is not always all guaranteed. Consult your Area Sales Manager or a financial professional for more information. E&OE'
  },
  {
    section: 'floorplans',
    text:
      'All plans, options, dimensions, specifications, lots and drawings are subject to availability and may change without notice. Some features may have limited, late, or no availability. Please check with your Area Sales Manager to confirm availability. The builder reserves the right to make modifications or substitutions should they be necessary. E.&O.E.'
  },
  {
    section: 'elevations',
    text:
      'All plans, options, dimensions, specifications, lots and drawings are subject to availability and may change without notice. Some features may have limited, late, or no availability. Please check with your Area Sales Manager to confirm availability. The builder reserves the right to make modifications or substitutions should they be necessary. E.&O.E.'
  },
  {
    section: 'palettes',
    text:
      'All plans, options, dimensions, specifications, lots and drawings are subject to availability and may change without notice. Some features may have limited, late, or no availability. Please check with your Area Sales Manager to confirm availability. The builder reserves the right to make modifications or substitutions should they be necessary. E.&O.E.'
  },
  {
    section: 'packages',
    text:
      'All plans, options, dimensions, specifications, lots and drawings are subject to availability and may change without notice. Some features may have limited, late, or no availability. Please check with your Area Sales Manager to confirm availability. The builder reserves the right to make modifications or substitutions should they be necessary. E.&O.E.'
  },
  {
    section: 'depositAmount',
    text:
      'Limited time offer. The Ownly calculated sale price is not always an accurate representation of final price. Prices are subject to change without notice, until a home reservation has been secured. E.&O.E.'
  },
  {
    section: 'summary',
    text:
      'Floorplans represent one version of the home and measurements are approximate. Square footage, measurements and layout may vary based on the elevation and/or lot. All elevations shown are artist’s concept only and will vary by community. The builder reserves the right to make modifications or substitutions should they be necessary. All plans, options, dimensions, specifications, lots and drawings are subject to availability and may change without notice. E.&O.E.'
  },
  {
    section: 'exportPagePdf',
    text:
      'Floorplans represent one version of the home and measurements are approximate. Square footage, measurements and layout may vary based on the elevation and/or lot. All elevations shown are artist’s concept only and will vary by community. The builder reserves the right to make modifications or substitutions should they be necessary. All plans, options, dimensions, specifications, lots and drawings are subject to availability and may change without notice. E.&O.E.'
  },
  {
    section: 'sfPriceDisclaimer',
    text:
      'Single-family home prices include [home, lot, GST, GST rebate if applicable, and additional fees]. Prices do not include additional design options, upgrades, or change orders. The Ownly calculated sale price is not always an accurate representation of final price. Prices are subject to change without notice, until a home reservation has been confirmed. For more information, contact your Area Sales Manager. E&OE.'
  },
  {
    section: 'mfPriceDisclaimer',
    text:
      'Prices do not include additional design options, upgrades, or change orders. The Ownly calculated sale price is not always an accurate representation of final price. Prices are subject to change without notice, until a home reservation has been confirmed. For more information, contact your Area Sales Manager. E&OE.'
  },
  {
    section: 'qpDepositInPlace',
    text: ''
  },
  {
    section: 'interestRate',
    text: ''
  }
]);
